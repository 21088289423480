import React, { useState, useEffect} from 'react';
// import Login from './components/LoginPage/LoginPage'
import "./Home.css";

import Header from "../../components/Header/Header"
import SpeedDials from '../../components/SpeedDials/SpeedDials'
import axios from 'axios'

import { useSelector } from 'react-redux'

function Home(props) {
    
	const [DisplayName, setDisplayName] = useState("Logging In")
	const [UserId, setUserId] = useState("- - - -")
	const [DialButtons, setDialButtons] = useState([])
    const userState = useSelector(state => state.user)

          let isMounted = true;               // note mutable flag

    useEffect(() => {
        if (userState.authenticated === false) {
            props.history.push('/')
        }
          return () => { isMounted = false }; // use cleanup to toggle value, if unmounted

    }, [userState, isMounted , userState.logoustated]);
    if(userState.logoustated){
        document.body.classList.remove('home-page');
        document.body.classList.add('login-page');
    }
    else{
    document.body.classList.add('home-page');
    document.body.classList.remove('login-page');

    }

    useEffect(() => {
        
        let session_separator_usrname = localStorage.getItem("session_separator_usrname");
        
        const body = {
            email: session_separator_usrname,
           
        }
        let config = {
            headers: {
              'Content-Type': 'text/plain; charset=UTF-8',
            }
        }
          
        setTimeout(() => {
            
            axios.post('https://php1.myriaconnect.com/api/web-text',body,config)
            .then(res => res.data.data)
            .then(text => {
                text = text.split('\n')
                text.push("END")
                let dialButtons = []
                text.forEach(element => {
                    let curr = element.split(":")
                    console.log("\nthsi is console"+element);
                    if ("DISPNAME" === curr[0]) {
                        setDisplayName(curr[1])
                        setUserId(session_separator_usrname)
                    }
                    else if ("CH1" === curr[0]) {
                        dialButtons.push(curr)
                    }
                    else if ("CH2" === curr[0]) {
                        dialButtons.push(curr)
                    }
                    else if ("CH3" === curr[0]) {
                        dialButtons.push(curr)
                    }
                    else if ("CH4" === curr[0]) {
                        dialButtons.push(curr)
                    }
                    else if ("CH5" === curr[0]) {
                        dialButtons.push(curr)
                    }
                    else if ("CH6" === curr[0]) {
                        dialButtons.push(curr)
                    }
                    else if ("CH7" === curr[0]) {
                        dialButtons.push(curr)
                    }
                    else if ("END" === curr[0]) {
                        setDialButtons(dialButtons)
                    }
                })
            })
            .catch(err => {
                console.log(err)
            }); 
        }, 3000);
        
    }, [])
    
	
    return (

        <div className={`media-size`} id='main_container'>
          <Header DisplayName={DisplayName} UserId={UserId} />
          <SpeedDials dialButtons={DialButtons} />
          </div>
          
    );
}

export default Home;

