import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './components/Home/Home';
import Login from './components/LoginPage/LoginPage'
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

import Session from "./components/Session/Session";

// Redux;
import { Provider } from 'react-redux';
import store from './redux/store';
import { connectToSocket, setStartUpState } from './redux/actions/stationActions';
import { CONNECTING } from './redux/types';

// BASE URL for axios, auth and middleware will be here soon;
axios.defaults.baseURL = 'https://php1.myriaconnect.com/api';
axios.defaults.proxy = {
    protocol: 'https',
	host: 'php1.myriaconnect.com',
    port: 1338
  };
class App extends Component {
	constructor() {
		super();
        this.store = store;
    }

	componentDidMount() {

		// Override console.log
		const originalConsoleLog = console.log;
		console.log = (message) => {
			originalConsoleLog(message); // Keeps the original console behavior
			sendLogToServer("log", message);
		};

		// Override console.error
		const originalConsoleError = console.error;
		console.error = (message) => {
			// Check if it's an Error object
			const errorMessage = message instanceof Error ? message.stack : message;
			originalConsoleError(errorMessage); // Keeps the original console behavior
			sendLogToServer("error", errorMessage);
		};

		// Capture global errors
		window.onerror = (message, source, lineno, colno, error) => {
			const errorMessage = error ? error.stack : `${message} at ${source}:${lineno}:${colno}`;
			sendLogToServer("globalError", errorMessage);
			return false; // Keeps the browser’s default error handling
		};

		function sendLogToServer(type, message) {
		let users_logdataData = {
			users_logs: JSON.stringify ({timestamp: new Date().toISOString(),type, message}),
		};
		let config = {
			headers: {
			'Content-Type': 'application/json; charset=UTF-8',
			}
		}
		axios
			.post("/users_log",users_logdataData,config)
			
		}

		

		this.store.dispatch(connectToSocket())//.then(ws => setStartUpState(ws))
		this.store.dispatch({
			type: CONNECTING
		})

	}

	render() {
		
		return (
			<Provider store={this.store}>
				<div className="container">
					<Router>
						<Session/>

						<Route path="/main" exact component={Home}/>
						<Route path="/" exact component={Login}/>
					</Router>
				</div>
			</Provider>
		);
	}
}


export default App;

